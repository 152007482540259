// form client
// =============================================
.loader {
   border: 16px solid #f3f3f3; /* Light grey */
   border-top: 16px solid #3498db; /* Blue */
   border-radius: 50%;
   width: 60px;
   height: 60px;
   animation: spin 2s linear infinite;
 }
 
 @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
 }


 
.pointer {
   li {
      cursor: pointer;
   }
}
.label-form-client {
    label {
    display: inline-block;
   //  width: 140px;
    text-align: right;

    }
    input{
       background: transparent;
       border: none;
       border-bottom: 4px dotted #000000;
       width: 100%;
       position:relative ; 
       top:-8px;
       
    }
    input:focus {
       outline:none;
   }
   input:hover{
   
      border-bottom: 1px solid chocolate;
   }
}


.table-form-client{
   border: 3px solid black;
   th{
      border: 3px solid black;
   }
   tr{
      border: 3px solid black;
   }
   td{
      border: 3px solid black;
   }
 }

 hr.dashed-line {
   padding: 0px;
   margin: 0px; 
   width: 100%;
 }

 
 .label-contrat-client {
   display:run-in;
   background: transparent;
   border: none;
   border-bottom: 2px dashed #000000;
   width: 150px;

      outline:none;
  
}
.center_date_in_box {
   vertical-align: middle !important;
}


// -----------------


* {
   margin: 0;
   padding: 0
}

html {
   height: 100%
}

p {
   color: grey
}

#heading {
   text-transform: uppercase;
   color: #0f2765;
   font-weight: normal
}

.msform {
   text-align: center;
   position: relative;
   margin-top: 20px
}

.msform fieldset {
   background: white;
   border: 0 none;
   border-radius: 0.5rem;
   box-sizing: border-box;
   width: 100%;
   margin: 0;
   padding-bottom: 20px;
   position: relative
}

.form-card {
   text-align: left
}

.msform fieldset:not(:first-of-type) {
   display: none
}

.w-300_px{
   width : 300px;
}
.w-60_px{
   width : 60px;
}

.text-consent-color {
   color : #fe6a19;
   // color : #1E2F55;
}

.consent-bold {
   font-weight: bold!important;
}

.consent-btn {
   border-radius: 0!important;
   background: #808080ad!important;
   margin-bottom: 30px;
   margin-top: 10px;
}

.consent-btn-success {
   background-color: #3b663b!important;
}

// .msform input,
// .msform textarea {
//    padding: 8px 15px 8px 15px;
//    border: 1px solid #ccc;
//    border-radius: 0px;
//    margin-bottom: 25px;
//    margin-top: 2px;
//    width: 100%;
//    box-sizing: border-box;
//    font-family: montserrat;
//    color: #2C3E50;
//    background-color: #ECEFF1;
//    font-size: 16px;
//    letter-spacing: 1px
// }

// .msform input:focus,
// .msform textarea:focus {
//    -moz-box-shadow: none !important;
//    -webkit-box-shadow: none !important;
//    box-shadow: none !important;
//    border: 1px solid #673AB7;
//    outline-width: 0
// }

.msform .action-button {
   width: 100px;
   background:white;
   font-weight: bold;
   color: #0f2765;
   border: 0 none;
   border-radius: 0px;
   cursor: pointer;
   padding: 10px 5px;
   margin: 10px 0px 10px 5px;
   float: right;
   box-shadow: 1.5px 2px 10px 3px;
   transition-property: all;
   transition-duration: 0.5s;

   
}

.msform .action-button:hover,
.msform .action-button:focus {
   background-color: #0f2765;
   color:white;
}

.msform .action-button-previous {
   width: 100px;
   background: #0f2765;
   font-weight: bold;
   color: white;
   border: 0 none;
   border-radius: 0px;
   cursor: pointer;
   padding: 10px 5px;
   margin: 10px 5px 10px 0px;
   float: right;
   transition-property: all;
   transition-duration: 0.5s;
   box-shadow: 1.5px 2px 10px 3px;

}

.msform .action-button-previous:hover,
.msform .action-button-previous:focus {
   background-color: white;
   color:#0f2765;
}

.card {
   z-index: 0;
   border: none;
   position: relative
}

.fs-title {
   font-size: 25px;
   color: #0f2765;
   margin-bottom: 15px;
   font-weight: normal;
   text-align: left
}

.purple-text {
   color: #0f2765;
   font-weight: normal
}

.steps {
   font-size: 25px;
   color: gray;
   margin-bottom: 10px;
   font-weight: normal;
   text-align: right
}

.fieldlabels {
   color: gray;
   text-align: left
}

#progressbar {
   margin-bottom: 30px;
   overflow: hidden;
   color: lightgrey
}

#progressbar .active {
   // color: #673AB7
   color: #0f2765
}

#progressbar li {
   list-style-type: none;
   font-size: 15px;
   width: 20%;
   float: left;
   position: relative;
   font-weight: 400
}

#progressbar #account:before {
   font-family: FontAwesome;
   content: "\f13e"
}

#progressbar #personal:before {
   font-family: FontAwesome;
   content: "\f007"
}

#progressbar #payment:before {
   font-family: FontAwesome;
   content: "\f030"
}

#progressbar #confirm:before {
   font-family: FontAwesome;
   content: "\f00c"
}

#progressbar li:before {
   width: 50px;
   height: 50px;
   line-height: 45px;
   display: block;
   font-size: 20px;
   color: #ffffff;
   background: lightgray;
   border-radius: 50%;
   margin: 0 auto 10px auto;
   padding: 2px
}

#progressbar li:after {
   content: '';
   width: 100%;
   height: 2px;
   background: lightgray;
   position: absolute;
   left: 0;
   top: 25px;
   z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
   background: #0f2765
}

.progress {
   height: 20px
}

.progress-bar {
   background-color: #0f2765
}



.full-width-input{
   width: 100% !important; 
}

ol > li::before{
   font-weight: bold;
   margin-left: 10px;
}

// Media querry for client_consent

@media screen and (max-width : 767px) {
   .card-style {
      width : 95%;
      box-shadow : none;

      &>.logo>a>img{
         width : 50%!important;
         margin-top : 0!important;
         left : 20px;
      }
   }

   .border-dark {
      border : .5px solid #686f768c !important;
      margin-top : 20px;
      padding-top : 10px;
      overflow-x: scroll;
   }

   .box-tax-year{
      border: 0.3px solid #cbcbcb;
   }

   .estim-tab{
      overflow-x: scroll;
   }
   
   .msform .action-button{
      box-shadow: -1px 2px 3px 1px;
      -webkit-box-shadow: -1px 2px 3px 1px;
      margin: 10px 10px 10px 5px;
   }

   .consent_logo img{
      min-width : 75%!important;
   }
}

@media screen and (max-width : 1200px ) {
   .consent-fstn,.consent-busnss{
      display: flex;
      flex-direction: column;
      >td{
         display: flex;
         flex-direction: column;
         >input{
            width: 100%;
         }
      }
   }

   .consent-busnss>td:nth-child(2){
      flex-direction: row;
      >div{
         width: 50%;
         >input{
            width : 90%;
         }
      }
   }
}