/* Slider
================================================== */

/*-- Main slide --*/

#box-slide{
   .item{
      height : 100vh;
      overflow: hidden;
   }
}

#main-slide {
   .carousel-item {
      min-height: 900px;
      color: #fff;
      background-position: 100% 100%;
      background-size: cover;
      backface-visibility: hidden;
      img {
         width: 100%;
         backface-visibility: hidden;
      }
      .slider-content {
         z-index: 1;
         opacity: 0;
         transition: opacity 500ms;
      }
      &.active .slider-content {
         opacity: 1;
         transition: opacity 100ms;
      }
   }
   .slider-content {
      padding: 50px 0 0 0;
      width: 100%;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      min-height: 900px;
   }
}

.slide-title {
   font-size: 36px;
   line-height: 48px;
   font-weight: 100;
   color: #fff;
   margin: 0;
}

.slide-sub-title {
   font-style: normal;
   font-size: 94px;
   line-height: 100px;
   margin: 10px 0 20px;
   color: #fff;
}

.slider-text {
   display: table;
   vertical-align: bottom;
   color: #fff;
   padding-left: 40%;
   width: 100%;
   padding-bottom: 0;
   padding-top: 20px;
   .slide-head {
      font-size: 36px;
      color: #0052a5;
      position: relative;
   }
}

.slider-img {
   display: block;
   position: absolute;
   top: -80px;
   left: 20%;
   width: 314px;
   max-height: 100%;
   img {
      display: block;
      position: relative;
      max-height: 100%;
      width: auto;
   }
}

.slider {
   &.btn {
      margin: 15px 5px 0;
      border: 2px solid transparent;
      &.btn-border {
         border: 2px solid #fff;
         color: #fff;
         &:hover {
            border: 2px solid transparent;
            color: #fff;
         }
      }
   }
}

.slider-description {
   color: #fff;
   font-size: 20px;
   line-height: 34px;
   margin: 20px 0 20px;
}

/* Carousel control */

.carousel-indicators li {
   width: 10px !important;
   height: 10px !important;
   margin: 1px !important;
   border-radius: 100% !important;
   border: 0 !important;
   background: rgba(255, 255, 255, 0.5);
}

#main-slide {
   .carousel-indicators li {
      &.active,
      &:hover {
         background: #2154cf;
         -moz-transition: all 300ms ease;
         transition: all 300ms ease;
         -ms-transition: all 300ms ease;
         -o-transition: all 300ms ease;
         transition: all 300ms ease;
      }
   }
   .carousel-control {
      &.left,
      &.right {
         opacity: 0;
         filter: alpha(opacity=0);
         background-image: none;
         background-repeat: no-repeat;
         text-shadow: none;
         transition: all .25s ease;
         -moz-transition: all .25s ease;
         -ms-transition: all .25s ease;
         -o-transition: all .25s ease;
         transition: all .25s ease;
      }
   }
   .carousel-indicators {
      opacity: 0;
      filter: alpha(opacity=0);
      background-image: none;
      background-repeat: no-repeat;
      text-shadow: none;
      transition: all .25s ease;
      -moz-transition: all .25s ease;
      -ms-transition: all .25s ease;
      -o-transition: all .25s ease;
      transition: all .25s ease;
   }
   &:hover {
      .carousel-control {
         &.left,
         &.right {
            opacity: 1;
            filter: alpha(opacity=100);
         }
      }
      .carousel-indicators {
         opacity: 1;
         filter: alpha(opacity=100);
      }
   }
   .carousel-control {
      .fa-angle-left,
      .fa-angle-right {
         position: absolute;
         top: 50%;
         z-index: 5;
         display: inline-block;
         transform: translateY(-50%);
         transform: translateY(-50%);
      }
      .fa-angle-left {
         left: 20px;
      }
      .fa-angle-right {
         right: 20px;
      }
      i {
         background: rgba(0, 0, 0, 0.3);
         color: #fff;
         line-height: 58px;
         width: 60px;
         height: 60px;
         font-size: 36px;
         border-radius: 0;
         -moz-transition: all 500ms ease;
         transition: all 500ms ease;
         -ms-transition: all 500ms ease;
         -o-transition: all 500ms ease;
         transition: all 500ms ease;
         &:hover {
            background: #2154cf;
            color: #fff;
         }
      }
   }
   .carousel-indicators {
      display: none !important;
   }
}

/*-- Animation */

.carousel-inner .carousel-item.active {
   h2,
   h3,
   p {
      animation: fadeInLeft 1.5s ease-in-out 300ms both;
   }
}

/*-- Owl slide common --*/

.owl-carousel {
   &.page-slider .owl-nav {
      margin: 0;
   }
   .owl-nav {
      text-align: center;
   }
   &.owl-theme {
      .owl-nav {
         [class*=owl-] {
            background: #2154cf;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 40px;
            height: 40px;
            line-height: 34px;
            font-size: 20px;
            text-align: center;
            margin: 0;
            border-radius: 0;
            transition: 400ms;
            -moz-transition: 400ms;
            -o-transition: 400ms;
            transition: 400ms;
            transform: translateY(-50%);
            transform: translateY(-50%);
            backface-visibility: hidden;
         }
         [class*=owl-prev] {
            right: 40px;
         }
         >div:hover {
            background: #272d33;
         }
      }
      &.owl-dark .owl-nav .disabled {
         background: #90a9e7;
         color: #fff;
         opacity: 1;
      }
   }
}

/* Dark bg */

.owl-theme .owl-dots {
   text-align: center;
   margin-top: 40px;
   position: relative;
   z-index: 1;
   .owl-dot {
      display: inline-block;
      span {
         width: 30px;
         height: 4px;
         margin: 5px 4px;
         background: #000;
         opacity: .2;
         display: inline-block;
      }
      &.active span {
         background: #2154cf;
         opacity: 1;
      }
   }
}

/* Owl Page slider */

.page-slider {
   &.owl-carousel.owl-theme .owl-nav [class*=owl-] {
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      height: 100px;
      line-height: 100px;
      border-radius: 0;
      transition: all 500ms ease;
      margin-top: -25px;
      &:hover {
         background: #2154cf;
      }
   }
   &.owl-theme {
      .owl-nav {
         div {
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 50%;
            width: 36px;
            height: 72px;
            line-height: 72px;
            font-size: 26px;
            text-align: center;
            margin: 0;
            border-radius: 0;
            transition: 400ms;
            -moz-transition: 400ms;
            -o-transition: 400ms;
            transition: 400ms;
            transform: translateY(-50%);
            transform: translateY(-50%);
         }
         div:hover {
            background: #2154cf;
            color: #fff;
         }
         [class*=owl-prev] {
            right: auto;
            left: 0;
            ;
         }
      }
   }
   .item {
      position: relative;
      z-index: 2;
      // min-height: 700px;
      background-position: 50% 50%;
      background-size: cover;
   }
   &.page-slider-small .item {
      max-height: 500px;
      min-height: auto;
   }
   .owl-item {
      .box-slider-content {
         opacity: 0;
         transition: opacity 1.5s;
      }
      &.active {
         .box-slider-content {
            opacity: 1;
            transition: opacity .8s;
            &.right {
              .box-slider-text {
                animation: slideInLeft .8s ease-out 1s both;
              }
            }
          }
         .box-slider-text {
            animation: slideInRight .8s ease-out 1s both;
         }
      }
   }
}

.page-slider-caption {
   position: absolute;
   bottom: 0;
   width: 100%;
   padding: 5px 20px;
   background: rgba(255, 255, 255, 0.9);
   h3 {
      font-size: 20px;
   }
   span {
      font-weight: 400;
   }
}

/* Owl Box slider */

.box-slider-content {
   top: 55.3%;
   padding: 0;
   position: absolute;
   width: 600px;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   &.bottom {
     top: 60%;
   }
   &.right {
     right: 3%;
     @media (max-width: 991px) {
       right: initial;
     }
   }
}

.box-slider-text {
  background: rgba(0, 0, 0, 0.1);
   display: inline-block;
   padding: 20px 30px;
   max-width: 750px;
   backdrop-filter: blur(10px);
}

.box-slider-text {
   .slider {
      &.btn {
         padding: 12px 40px;
         &.btn-border {
            border: 2px solid #fff;
            color: #fff;
            &:hover {
               color: #fff;
               border-color: transparent;
            }
            &.btn-book{
               &:hover {
                  color: #0f2765;
                  border-color:#fff!important;
                  background-color:#fff!important;
               }
            }
         }
      }
   }
}

.box-slide-description {
  color: #fff;
  font-size:22px;
  text-transform : uppercase;
}

.box-slide-title {
   font-size: 28px;
   font-weight: 400;
   margin: 0;
   color: #fff;
   font-family: 'gilroy', sans-serif;
}

.box-slide-sub-title {
   font-size: 36px;
   margin: 8px 0 10px;
   color: #fff;
   line-height : 1;
}

.box-slide.owl-theme .owl-controls {
   margin: -20px;
}

/*-- Slider pages --*/

#main-slide.slider-pages .item {
   min-height: 340px;
   color: #fff;
   background-position: 50% 50%;
   background-size: cover;
   background-size: cover;
   backface-visibility: hidden;
}

/*-- Fixes --*/

.mrt-90{
   @media screen and (min-width: 992px) {
      margin-top : 90px;
   }
}

.title-text{
   font-size: 34px;
   margin: 8px 0 10px;
   line-height: 1;
   text-transform: uppercase;
   font-weight: bolder;
}

.width-500{
   width : 500px!important;
   @media screen and (max-width : 400px){
      width : 300px;
   }
}

.width-620{
   width : 620px!important;
   @media screen and (max-width : 400px){
      width : 300px;
   }
}

.width-650{
   width : 650px!important;
   @media screen and (max-width : 400px){
      width : 300px;
   }
}

.width-700{
   width : 700px!important;
   @media screen and (max-width : 400px){
      width : 300px;
   }
}

.strong-prestige{
   color:#fe6a19;
   font-size : 24px;
}

.hidden-h1{
   visibility : hidden;
   height : 0;
}
.background-top{
   background-position: top!important;
}