$color_1: #fff;
$background-color_1: #fff;
$hambuger_height : 45px;

/* CSS for the hamburger menu button */
/* CSS for phone and envelope icons */
/* Styles for the slider */
/* Styles for the slider */
/* Css for myhidenav */
/* Media queries */
#btns-t {
	// height: $hambuger_height;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	background: none;
	border: none;
	outline: none;
	padding: 0 6px;
	margin-right: 10px;
	margin-left: 6px;
	z-index: 90;
	cursor: pointer;
}
.ic-icon {
	width: 41px;
	height: 5px;
	background-color: $background-color_1;
}
.icons {
	height: $hambuger_height;
	background: none;
	border: none;
	outline: none;
	margin-right: 7px;
	margin-bottom: 0;
	position: relative;
	span {
		height: $hambuger_height;
		flex-direction: column;
		justify-content: space-between;
	}
	i {
		font-size: 18px;
		color: $color_1;
	}
	li {
		height: 20px;
		position: relative;
		list-style: none;
		display: inherit;
    	vertical-align: top;
		&:hover {
			.tel {
				width: 95px;
				transition: all 0.5s ease;
			}
			.mail {
				width: 172px;
				transition: all 0.5s ease;
			}
			.slider {
				a {
					visibility: visible;
					opacity: 1;
					transition: all 1s 0.2s ease;
				}
			}
		}
	}
	.slider {
		content: "";
		position: absolute;
		top: 0;
		right: 4px;
		width: 0;
		height: 20px;
		background: #1e2f55 !important;
		border-radius: 3px;
		margin-right: 20px;
		transition: all 0.5s 0.3s ease;
		font-weight: bold;
		white-space: nowrap;
		line-height: 1;
		a {
			color: $color_1;
			font-size: 13px;
			text-align: center;
			opacity: 0;
			visibility: hidden;
			transition: all 0.6s ease;
			padding-left: 5px;
			padding-right: 5px;
		}
	}
	&::after{
		content: "";
		position: absolute;
		top: 0;
		right: -10px;
		height: 100%;
		width: 1px;
		background-color: #fff;
	}
}
#myhidenav {
	flex-grow: 1;
	z-index: 10000;
	margin-right : 10px;
   .dropdown {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 700;
      color : #fff;

      .dropdown-menu {
         color : #FFF;
		 background: rgba(0, 0, 0, 0.1);
		 -webkit-backdrop-filter: blur(10px);
		 backdrop-filter: blur(10px);
      }

      &::after{
         float: right;
         right : -30px;
      }
   }
}

.colored-nav {
   #btns-t{
      span{
         background-color: #fe6a19!important;
      }
   }
   .icons{
      i{
         color: #fe6a19!important;
      }
	  &::after{
		background-color: #fe6a19!important;
	  }
   }
}

@media only screen and (max-width: 991px) {
	#myhidenav {
		position: absolute;
		top: 100px;
		width: 90%;
      	background-color: #252a37;
      	left: 5%;

		ul {
			width: 90%;
		}
	}
	.icons {
		z-index: 1000;
		
		span:nth-child(2) {
			li {
				position : relative;
				display: block;
				width : 20px;
				i {
					position: absolute;
					top: 2px;
				}
			}
		}
	}
}
