.padding-box-question{
    padding-top: 40px;
    padding-bottom : 40px;
}

.header-box-question{
    height: 40px;
}

.box-tax-year{
    border: 1px solid black;
    padding: 10px;
}

.row-question{
    display:flex;
    flex-direction: column;
    border-radius: 10px;
}
.yes-no-part{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.row-question:hover{
    background-color:lightsalmon;
    cursor: pointer;

}
.hidden-yes-no-part{
    display: flex;

}

.text-question{
    flex: 1;
}

.yes-no{
    flex:0.2;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.card-style{
    width: 75%;
    box-shadow: 1.5px 2px 10px 3px;
}

input[type=file]::-webkit-file-upload-button {
    border-radius: 50px;
    border: 2px solid #007bff;
    padding: .2em .4em;
    border-radius: .2em;
    background-color:#007bff;
    transition: 0.5s;
  }
  
  input[type=file]::file-selector-button {
    border: 2px solid #007bff;
    padding: .2em .4em;
    border-radius: .2em;
    background-color: #007bff;
    transition: 0.5s;
  }
  
  input[type=file]::-webkit-file-upload-button:hover {
    background-color: #81ecec;
    border: 2px solid #00cec9;
    cursor: pointer;
  }
  
  input[type=file]::file-selector-button:hover {
    background-color: #81ecec;
    border: 2px solid #00cec9;
    cursor: pointer;
  }