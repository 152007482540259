// ASAP FONT

// @import url(https://fonts.googleapis.com/css?family=Asap:400,400i,500,500i,600,600i,700,700i);

/* vietnamese */
@font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 400;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3OUBGEe.woff2) format('woff2');
   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
 }
 /* latin-ext */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 400;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3KUBGEe.woff2) format('woff2');
   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
 }
 /* latin */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 400;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3yUBA.woff2) format('woff2');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
 }
 /* vietnamese */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 500;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3OUBGEe.woff2) format('woff2');
   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
 }
 /* latin-ext */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 500;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3KUBGEe.woff2) format('woff2');
   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
 }
 /* latin */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 500;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3yUBA.woff2) format('woff2');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
 }
 /* vietnamese */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 600;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3OUBGEe.woff2) format('woff2');
   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
 }
 /* latin-ext */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 600;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3KUBGEe.woff2) format('woff2');
   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
 }
 /* latin */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 600;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3yUBA.woff2) format('woff2');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
 }
 /* vietnamese */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 700;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3OUBGEe.woff2) format('woff2');
   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
 }
 /* latin-ext */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 700;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3KUBGEe.woff2) format('woff2');
   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
 }
 /* latin */
 @font-face {
   font-family: 'Asap';
   font-style: italic;
   font-weight: 700;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO7CniXp96ayz4E7kSn66aGLdTylUAMa3yUBA.woff2) format('woff2');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
 }
 /* vietnamese */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 400;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hGW36MAA.woff2) format('woff2');
   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
 }
 /* latin-ext */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 400;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hHW36MAA.woff2) format('woff2');
   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
 }
 /* latin */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 400;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hJW34.woff2) format('woff2');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
 }
 /* vietnamese */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 500;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hGW36MAA.woff2) format('woff2');
   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
 }
 /* latin-ext */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 500;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hHW36MAA.woff2) format('woff2');
   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
 }
 /* latin */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 500;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hJW34.woff2) format('woff2');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
 }
 /* vietnamese */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 600;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hGW36MAA.woff2) format('woff2');
   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
 }
 /* latin-ext */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 600;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hHW36MAA.woff2) format('woff2');
   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
 }
 /* latin */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 600;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hJW34.woff2) format('woff2');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
 }
 /* vietnamese */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 700;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hGW36MAA.woff2) format('woff2');
   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
 }
 /* latin-ext */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 700;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hHW36MAA.woff2) format('woff2');
   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
 }
 /* latin */
 @font-face {
   font-family: 'Asap';
   font-style: normal;
   font-weight: 700;
   font-stretch: 100%;
   src: url(https://fonts.gstatic.com/s/asap/v30/KFO9CniXp96a4Tc2DaTeuDAoKsE615hJW34.woff2) format('woff2');
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
 }
// =================== END OF ASAP ========================
@font-face {
   font-family: 'gilroy';
   src: url('/asset/fonts/gilroy/gilroy-light-webfont.eot');
   src: url('/asset/fonts/gilroy/gilroy-light-webfont.eot?#iefix') format("embedded-opentype"), url('/asset/fonts/gilroy/gilroy-light-webfont.woff2') format("woff2"), url('/asset/fonts/gilroy/gilroy-light-webfont.woff') format("woff"), url('/asset/fonts/gilroy/gilroy-light-webfont.ttf') format("truetype"), url('/asset/fonts/gilroy/gilroy-light-webfont.svg#gilroylight') format("svg");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: 'gilroy';
   src: url('/asset/fonts/gilroy/gilroy-extrabold-webfont.eot');
   src: url('/asset/fonts/gilroy/gilroy-extrabold-webfont.eot?#iefix') format("embedded-opentype"), url('/asset/fonts/gilroy/gilroy-extrabold-webfont.woff2') format("woff2"), url('/asset/fonts/gilroy/gilroy-extrabold-webfont.woff') format("woff"), url('/asset/fonts/gilroy/gilroy-extrabold-webfont.ttf') format("truetype"), url('/asset/fonts/gilroy/gilroy-extrabold-webfont.svg#gilroyextrabold') format("svg");
   font-weight: 700;
   font-style: normal;
}
